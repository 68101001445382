// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import Layout from "components/layout"
import LoginModal from "components/login-form"

export default () => {
  const [params, setParams] = useState(new URLSearchParams(""))

  useEffect(() => {
    setParams(new URLSearchParams(window.location.search))
  }, [params.get("voucher")])

  const voucherCode = params.get("voucher")

  return (
    <Layout title="Login" hideContent={true}>
      <LoginModal
        fullScreen
        title="Sign up to Ember"
        description={
          voucherCode
            ? `The voucher code ${voucherCode} will be applied automatically. Your credit will be used for future bookings when you are logged in to your account.`
            : "You'll be sent an email with a link to confirm your new account. If you already have an account, you'll be sent a link to log in."
        }
        buttonText="Sign up"
        voucherCode={voucherCode}
      />
    </Layout>
  )
}
